<template>
  <v-container fluid class="height-100 bkg-gray">
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-system-bar
            height="60"
            color="primary"
            class="white--text elevation-5"
          >
            <v-card-title>
              <h3>Resumation Career Center: Error</h3>
            </v-card-title>
          </v-system-bar>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              Wow! We're sorry, something isn't lined up quite right for the
              environment you're trying to reach.
            </span>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div class="d-flex justify-center">
              <v-btn color="secondary" class="grey--text text--darken-4" @click="hardNavTo('/Login')"> Try Again </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: "CareerError",
  data: () => ({}),
  async mounted() {},
  methods: {
    hardNavTo(href) {
      window.location.href = href;
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
  },
  computed: {},
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}

.bkg-gray {
    background-color: var(--v-resumeErrorBox-base);
}
</style>
